import React, { useState } from "react";
import "./food.css";
import { Link } from "react-router-dom";

const FoodCard = (props) => {
  const [value, setValue] = useState(false);
  return (
    <Link to="#">
      <div className="w-full">
        <div className="relative">
          <img src={props.data.image} alt="" className="order-img" onMouseEnter={() => setValue(true)} onMouseLeave={() => setValue(false)}/>
          <div className={`transition  ${value ? "order-class transition" : "d-none"} absolute top-[-30px] right-[-10px] uppercase justify-center items-center text-center leading-[25px] text-[20px] font-bold text-[green] h-[100px] flex items-center justify-center w-[100px] bg-[#FFDD00] rounded-[50%]`}>
            Order <br />
            Now
          </div>
        </div>
        <div className="flex justify-start items-start">
          <div className="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="64" viewBox="0 0 51 64" fill="none">
              <path d="M19.4409 62.1836C17.9812 56.7826 15.9117 51.5861 15.0948 46.0136C14.2778 40.3754 14.2676 34.5326 15.2289 28.9116C17.1514 17.604 23.2231 7.44602 32.6804 0.861187C33.1458 0.551979 32.5016 -0.0642545 32.0887 0.20549C21.9156 6.48278 15.4504 16.7727 13.2398 28.4614C12.1279 34.2861 12.053 40.2013 12.9426 46.056C13.832 51.8451 15.2597 57.7384 18.8114 62.4602C18.9891 62.7291 19.5531 62.5839 19.4409 62.1836Z" fill="#003B4A"></path>
            </svg>
            <div className="ml-[5px] mt-[-10px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23" fill="none">
                <path d="M21.2115 16.6155C21.7543 11.8354 22.4677 7.0419 22.6692 2.22288C22.7453 0.686561 19.9696 0.697009 19.9395 2.23983C19.8749 6.55315 19.318 10.8343 19.0959 15.1348C13.3305 12.8188 7.91143 9.70801 2.14598 7.39207C1.43679 7.1042 0.637886 8.05067 1.46536 8.43029C7.6384 11.1789 13.3602 14.8014 19.5659 17.4516C20.2883 17.7788 21.1342 17.4297 21.2115 16.6155Z" fill="#003B4A"></path>
              </svg>
            </div>
          </div>
          <div>
            <div className="food-text flex text-center mt-[20px] lg:mt-[0px] ml-[10px] lg:mt-[50px]">
              {props.data.heading1}{" "}
              <span className="text-[#fd0] ml-[8px]">
                {props.data.heading2}
              </span>{" "}
              <span className="text-[#fd0] uppercase ml-[8px]">
                {props.data.heading3}
              </span>
            </div>
            <div className="food-text flex text-center ml-[10px] mt-[10px]">
              {props.data.heading4}
            </div>
            <div className="text-[grey] ml-[10px] w-[70%] max-w-[270px] text-start">
              {props.data.text}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default FoodCard;