import "./App.css";
import Location from "./Component/Location/Location";
import Privacy from "./Component/Privacy/Privacy";
import Menu from "./Component/Menu/Menu";
import AboutUs from "./Component/About/About";
import Home from "./Component/Home/Home";
import { Routes, Route } from "react-router-dom";
import MultipleItems from "./Component/SampleSlider/SampleSlider";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/MultipleItems" element={<MultipleItems />} />
      <Route path="/locations" element={<Location />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/menu" element={<Menu />} />
    </Routes>
  );
}

export default App;