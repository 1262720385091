import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./slider.css";
import { GrLinkNext } from "react-icons/gr";
import { IoArrowBack } from "react-icons/io5";
function MultipleItems() {
  const [data] = useState([
    {
      image: require("../Menu/images/classica.png"),
      type: "",
      heading1: "CLASSICA",
      heading2: "19.99",
      heading3: "",
      heading4: "",
      text: "Tomatoes Sauce, Cherry Tomato, Fresh Mozzarella, Topped with Fresh Basil",
    },
    {
      image: require("../Menu/images/lagricia.png"),
      type: "",
      heading1: "LA GRICIA",
      heading2: "19.99",
      heading3: "",
      heading4: "",
      text: "Garlic Paste, Mozzarella Cheese, Topped with Parsley and Pecorino Romano Cheese",
    },
    {
      image: require("../Menu/images/vegetariana.png"),
      type: "",
      heading1: "VEGETARIANA",
      heading2: "22.99",
      heading3: "",
      heading4: "Burger",
      text: "Tomato Sauce, Mozzarella Cheese, Black Olives, Mushrooms, Onions, Green Peppers, Topped with Oregano and Pecorino Romano Cheese",
    },
    {
      image: require("../Menu/images/boardwalkfries.png"),
      type: "",
      heading1: "BUFALINA",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Buffalo Sauce mixed with Tomato Sauce, Mozzarella Cheese, Buffalo Chicken, Red Onions, Topped with Pecorino Romano Cheese",
    },
    {
      image: require("../Menu/images/pollogriglia.png"),
      type: "",
      heading1: "POLLO GRIGLIA",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, BBQ Chicken, Red Onions, Jalapenos",
    },
    {
      image: require("../Menu/images/lagianduia.png"),
      type: "",
      heading1: "LA GIANDUIA",
      heading2: "17.99",
      heading3: "",
      heading4: "",
      text: "Nutella Spread, topped with Powdered Sugar",
    },
    {
      image: require("../Menu/images/pasticciodecarne.png"),
      type: "",
      heading1: "PASTICCIO DE CARNE",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni, Sausage, Ham, Meatballs",
    },
    {
      image: require("../Menu/images/cacioepepe.png"),
      type: "",
      heading1: "CACIO E PEPE",
      heading2: "22.99",
      heading3: "",
      text: "Hot Chilly Truffle, Garlic Paste, Mozzarella Cheese, topped with Parsley and Pepper Flakes",
    },
    {
      image: require("../Menu/images/suprema.png"),
      type: "",
      heading1: "SUPREMA",
      heading2: "22.99",
      heading3: "",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni, Sausage, Onions, Green Peppers, Mushrooms, topped with Pecorino Romano Cheese",
    },
    {
      image: require("../Menu/images/labianca.png"),
      type: "",
      heading1: "LA BIANCA",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Black Truffle and Mushroom, Garlic, Olive oil, Fresh Mozzarella, topped with Fresh Basil and Pecorino Romano Cheese",
    },
    {
      image: require("../Menu/images/spizy.png"),
      type: "",
      heading1: "SPIZY",
      heading2: "22.99",
      heading3: "",
      text: "Hot Chilly Truffle, Mozzarella Cheese, Sausage, Mushrooms, Caramelized Onions, Topped Fresh Basil and Red Pepper Flakes",
    },
    {
      image: require("../Menu/images/pestobasilico.png"),
      type: "",
      heading1: "PESTO BASILICO",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Ranch, Pesto Sauce, Garlic Paste, Fresh Mozzarella, Basil Leaves, and Red pepper Flakes",
    },
  ]);

  const [activeIndex, setActiveIndex] = useState(2);
  const renderSlides = () => {
    const totalSlides = 50;
    const slidesToShow = 5;
    const centerIndex = Math.floor(slidesToShow / 2);

    const slides = data.map((item, index) => {
      let margin;
      let transfor;
      let scale = 1;
      let zIndex = 1;
      let orderNowText = '';
      // Calculate margin based on position relative to the center slide
      const distanceFromCenter = Math.abs(index - activeIndex);
      let transform = 0;
      if (index === activeIndex) {
        margin = 50; // Center slide

      } else if (distanceFromCenter === 1) {
        // margin = window.innerWidth <= 767 ? 70 : 150; // Slides adjacent to center
        margin = 70;
        transfor = 15
      } else {
        // margin = window.innerWidth <= 767 ? 130 : 250;
        margin = 150; // Other slides
        transfor = -15
      }
      if (index < activeIndex) {
        transform = -15;
      } else if (index > activeIndex) {
        transform = 15;
      }
      else {
        // Active slide
        scale = 1.2; // Zoom in on the active slide
        zIndex = 22;
        orderNowText = <div className="bg-[#fd0] p-[10px] md:p-[20px] rounded-[50%] z-10 absolute left-[30%] text-center text-[#075c40] bottom-[0%] uppercase text-[12px] md:text-[20px] font-bold"><a href="/locations">Order   <br /> Now</a></div>;
      }
      return (
        <div key={index} className=" relative ">
          <img src={item.image} alt="" className="" style={{ marginTop: `${margin}px`, transform: `rotate(${transform}deg)  scale(${scale})   `, }}/>
          {orderNowText}
        </div>
      );
    });
    return slides;
  };

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    afterChange: (index) => setActiveIndex(index),
    responsive: [
      {
        breakpoint: 1258,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="previosu">
        <button onClick={onClick}>
          <GrLinkNext color="white" className="btnIcon" size={30} fill="white" />
        </button>
      </div>
    );
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="next">
        <button onClick={onClick}>
          <IoArrowBack color="white" className="btnIcon" size={30} />
        </button>
      </div>
    );
  };

  const getCenterHeading = () => {
    if (data.length > 0) {
      return data[activeIndex].heading1;
    }
    return "";
  };

  const [animateSlider, setAnimateSlider] = useState(true);

  useEffect(() => {
    setAnimateSlider(false);
  }, []);

  const getCenterHeading1 = () => {
    if (data.length > 0) {
      return data[activeIndex].heading2;
    }
    return "";
  };

  const getCenterHeading2 = () => {
    if (data.length > 0) {
      return data[activeIndex].heading3;
    }
    return "";
  };

  return (
    <div className={`relative pb-[20px] 2xl:pt-[20px] ${animateSlider ? 'animate-slide-in' : ''}`}>
      <div className="arraow">
        <div className="ml-[15px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="47" viewBox="0 0 46 47" fill="none" className="arrowback">
            <path d="M1.01958 45.9407C2.71928 40.6105 3.79559 35.1215 6.1285 29.9953C8.49688 24.8139 11.6542 19.8976 15.5079 15.6942C23.2508 7.23213 33.858 1.98456 45.3746 1.57465C45.9333 1.56692 45.7258 0.699952 45.2326 0.702919C33.281 0.466499 22.2716 5.61174 14.0801 14.2381C9.98944 18.5311 6.72136 23.462 4.29662 28.8648C1.90738 34.2123 -0.0859817 39.9391 0.340649 45.8321C0.34424 46.1544 0.897023 46.338 1.01958 45.9407Z" fill="#E8E6D9"></path>
          </svg>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none" className="arrowMian">
            <path d="M15.9848 19.2382C19.0311 15.5147 22.2281 11.8725 25.0086 7.93143C25.905 6.68139 23.5664 5.18617 22.7052 6.46656C20.3137 10.0568 17.5259 13.3533 15.0091 16.8474C11.4182 11.7769 8.54926 6.22598 4.95844 1.15549C4.51836 0.529263 3.33405 0.891867 3.82383 1.65929C7.52276 7.31433 10.369 13.4593 14.1487 19.0492C14.5785 19.7157 15.4787 19.8806 15.9848 19.2382Z" fill="#E8E6D9"></path>
          </svg>
        </div>
        <div className="uppercase">
          <span className="text-[15px] sm:text-[30px] text-[#075c40] font-bold">{getCenterHeading()}</span>{" "}
          <span className="text-[15px] sm:text-[30px] text-[#fd0] font-bold">{getCenterHeading1()}</span>{" "}
          <span className="text-[#fd0] text-[20px] font-bold">{getCenterHeading2()}</span>
        </div>
      </div>
      <div className="arraow1">
        <div className="uppercase text-center leading-[12px] lg:leading-[25px] 2xl:leading-[30px] ml-[30px] sm:ml-[50px]">
          <span className="text-[8px] leading-[12px] sm:text-[12px] md:text-[15px] text-center text-[#075c40] font-bold">we are <br /> famous for <br /> our dough!  </span>{" "}
        </div>
        <div className="ml-[15px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="47" viewBox="0 0 46 47" fill="none" className="arrowback">
            <path d="M1.01958 45.9407C2.71928 40.6105 3.79559 35.1215 6.1285 29.9953C8.49688 24.8139 11.6542 19.8976 15.5079 15.6942C23.2508 7.23213 33.858 1.98456 45.3746 1.57465C45.9333 1.56692 45.7258 0.699952 45.2326 0.702919C33.281 0.466499 22.2716 5.61174 14.0801 14.2381C9.98944 18.5311 6.72136 23.462 4.29662 28.8648C1.90738 34.2123 -0.0859817 39.9391 0.340649 45.8321C0.34424 46.1544 0.897023 46.338 1.01958 45.9407Z" fill="#E8E6D9"></path>
          </svg>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none" className="arrowMian">
            <path d="M15.9848 19.2382C19.0311 15.5147 22.2281 11.8725 25.0086 7.93143C25.905 6.68139 23.5664 5.18617 22.7052 6.46656C20.3137 10.0568 17.5259 13.3533 15.0091 16.8474C11.4182 11.7769 8.54926 6.22598 4.95844 1.15549C4.51836 0.529263 3.33405 0.891867 3.82383 1.65929C7.52276 7.31433 10.369 13.4593 14.1487 19.0492C14.5785 19.7157 15.4787 19.8806 15.9848 19.2382Z" fill="#E8E6D9"></path>
          </svg>
        </div>
      </div>
      <div className="uppercase text-center pb-[20px] 2xl:pb-[50px] 2xl:pt-[50px] text-white  text-[50px] lg:text-[80px] 2xl:text-[120px]  font-bold leading-[92px] ">
        i'm craving{" "}
        <span className="text-[#fd0]   border-b-[10px] border-b border-[#fd0] border-dashed ">
          {getCenterHeading()}
        </span>
      </div>
      <div className="slider-container ">
        <Slider
          {...settings}
          initialSlide={activeIndex}
          prevArrow={<NextArrow />}
          nextArrow={<PrevArrow />}
        >
          {renderSlides()}
        </Slider>
      </div>
    </div>
  );
}

export default MultipleItems;