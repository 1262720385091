import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { IoSearch } from "react-icons/io5";
import FoodCard from "./FoodCard";
import "./food.css";

const Menu = () => {
  const [value, setValue] = useState("");
  const [headerValue, setHeader] = useState("all");
  const header = [
    {name: "pinza",},
    {name: "sides",},
    {name: "desserts",},
    {name: "beverages",},
    {name: "most popular",},
  ];
  console.log("headerValue", headerValue);
  const [data] = useState([
    {
      image: require("./images/classica.png"),
      type: "pinza",
      heading1: "CLASSICA",
      heading2: "19.99",
      heading3: "",
      heading4: "",
      text: "Tomatoes Sauce, Cherry Tomato, Fresh Mozzarella, Topped with Fresh Basil",
    },
    {
      image: require("./images/lagricia.png"),
      type: "pinza",
      heading1: "LA GRICIA",
      heading2: "19.99",
      heading3: "",
      heading4: "",
      text: "Garlic Paste, Mozzarella Cheese, Topped with Parsley and Pecorino Romano Cheese",
    },
    {
      image: require("./images/vegetariana.png"),
      type: "most popular",
      heading1: "VEGETARIANA",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, Black Olives, Mushrooms, Onions, Green Peppers, Topped with Oregano and Pecorino Romano Cheese",
    },
    {
      image: require("./images/boardwalkfries.png"),
      type: "pinza",
      heading1: "BUFALINA",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Buffalo Sauce mixed with Tomato Sauce, Mozzarella Cheese, Buffalo Chicken, Red Onions, Topped with Pecorino Romano Cheese",
    },
    {
      image: require("./images/pollogriglia.png"),
      type: "pinza",
      heading1: "POLLO GRIGLIA",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, BBQ Chicken, Red Onions, Jalapenos",
    },
    {
      image: require("./images/lagianduia.png"),
      type: "pinza",
      heading1: "LA GIANDUIA",
      heading2: "17.99",
      heading3: "",
      heading4: "",
      text: "Nutella Spread, topped with Powdered Sugar",
    },
    {
      image: require("./images/pasticciodecarne.png"),
      type: "pinza",
      heading1: "PASTICCIO DE CARNE",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni, Sausage, Ham, Meatballs",
    },
    {
      image: require("./images/cacioepepe.png"),
      type: "pinza",
      heading1: "CACIO E PEPE",
      heading2: "22.99",
      heading3: "",
      text: "Hot Chilly Truffle, Garlic Paste, Mozzarella Cheese, topped with Parsley and Pepper Flakes",
    },
    {
      image: require("./images/suprema.png"),
      type: "pinza",
      heading1: "SUPREMA",
      heading2: "22.99",
      heading3: "",
      text: "Tomato Sauce, Mozzarella Cheese, Pepperoni, Sausage, Onions, Green Peppers, Mushrooms, topped with Pecorino Romano Cheese",
    },
    {
      image: require("./images/labianca.png"),
      type: "pinza",
      heading1: "LA BIANCA",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Black Truffle and Mushroom, Garlic, Olive oil, Fresh Mozzarella, topped with Fresh Basil and Pecorino Romano Cheese",
    },
    {
      image: require("./images/spizy.png"),
      type: "pinza",
      heading1: "SPIZY",
      heading2: "22.99",
      heading3: "",
      text: "Hot Chilly Truffle, Mozzarella Cheese, Sausage, Mushrooms, Caramelized Onions, Topped Fresh Basil and Red Pepper Flakes",
    },
    {
      image: require("./images/pestobasilico.png"),
      type: "most popular",
      heading1: "PESTO BASILICO",
      heading2: "22.99",
      heading3: "",
      heading4: "",
      text: "Ranch, Pesto Sauce, Garlic Paste, Fresh Mozzarella, Basil Leaves, and Red pepper Flakes",
    },
    {
      image: require("./images/garlicsticks.png"),
      type: "sides",
      heading1: "GARLIC BREAD STICKS",
      heading2: "4.99",
      heading3: "",
      heading4: "",
      text: "Served with Marinara",
    },
    {
      image: require("./images/originalfries.png"),
      type: "sides",
      heading1: "ORIGINAL FRENCH FRIES",
      heading2: "4.99",
      heading3: "",
      heading4: "",
      text: "Served with Honey Mustard",
    },
    {
      image: require("./images/boardwalkfries.png"),
      type: "sides",
      heading1: "BOARDWALK FRIES",
      heading2: "4.99",
      heading3: "",
      heading4: "",
      text: "Topped in Salt and Vinegar",
    },
    {
      image: require("./images/garlicfries.png"),
      type: "sides",
      heading1: "GARLIC PARM FRIES",
      heading2: "4.99",
      heading3: "",
      text: "Topped in Garlic Sauce and Parsley",
    },
    {
      image: require("./images/trufflefries.png"),
      type: "sides",
      heading1: "TRUFFLE FRIES",
      heading2: "4.99",
      heading3: "",
      heading4: "",
      text: "Tossed in Truffle and Garlic Paste topped with Parsley, served with ketchup",
    },
    {
      image: require("./images/chickentenders.png"),
      type: "sides",
      heading1: "CHICKEN TENDERS",
      heading2: "8.99",
      heading3: "3PC",
      heading4: "",
      text: "Choice Sauce: Ranch, Blue Cheese, or Honey Mustard",
    },
    {
      image: require("./images/combo.png"),
      type: "sides",
      heading1: "MAKE IT A COMBO",
      heading2: "12.99",
      heading3: "",
      heading4: "",
      text: "Chicken Tenders, Original Fries, & a Soda",
    },
    {
      image: require("./images/boardwalkfries.png"),
      type: "desserts",
      heading1: "COOKIES",
      heading2: "2.99",
      heading3: "2PC",
      heading4: "",
      text: "",
    },
    {
      image: require("./images/boardwalkfries.png"),
      type: "desserts",
      heading1: "BROWNIE",
      heading2: "3.99",
      heading3: "",
      text: "",
    },
    {
      image: require("./images/boardwalkfries.png"),
      type: "desserts",
      heading1: "CANNOLIS",
      heading2: "5.99",
      heading3: "",
      heading4: "2PCS",
      text: "",
    },
    {
      image: require("./images/lagianduia.png"),
      type: "desserts",
      heading1: "LA GIANDUIA DIPPERS",
      heading2: "9.99",
      heading3: "",
      heading4: "",
      text: "Nutella Spread, topped with Powdered Sugar",
    },
    {
      image: require("./images/sanpellegrino.png"),
      type: "beverages",
      heading1: "SAN PELLEGRINO",
      heading2: "2.99",
      heading3: "",
      heading4: "",
      text: "",
    },
    {
      image: require("./images/cocacola.png"),
      type: "beverages",
      heading1: "CAN OF COKE",
      heading2: "2.49",
      heading3: "",
      heading4: "",
      text: "",
    },
    {
      image: require("./images/dietcoke.png"),
      type: "beverages",
      heading1: "CAN OF DIET COKE",
      heading2: "2.49",
      heading3: "",
      heading4: "",
      text: "",
    },
    {
      image: require("./images/sprite.png"),
      type: "beverages",
      heading1: "CAN OF SPRITE",
      heading2: "2.49",
      heading3: "",
      heading4: "",
      text: "",
    },
  ]);
  const [activeHeader, setActiveHeader] = useState(null);

  const handleHeader = (name) => {
    setActiveHeader(name);
  };
  const filteredData = data.filter((item) => {
    const matchesHeader =
      value.toLowerCase() === "all" ||
      item.heading1.toLowerCase().includes(value.toLowerCase());
    const matchesType = headerValue === "" || item.type === headerValue;

    // If headerValue is "All", show all products
    return (
      matchesHeader && (headerValue.toLowerCase() === "all" || matchesType)
    );
  });
  const [filteredLength, setFilteredLength] = useState(0);

  useEffect(() => {
    // Calculate and store the length of filtered data for the selected header
    if (headerValue) {
      const length = filteredData.filter(data => data.type === headerValue).length;
      setFilteredLength(length);
    }
  }, [filteredData, headerValue]);

  return (
    <div>
      <Header />
      <div className="py-[100px] w-[95%] xl:w-[1170px] mx-auto ">
        <div className="flex justify-between max-w-[600px] border-b-[5px] border-dashed border-[#74c3d5]">
          <input type="text" placeholder="TYPE WHAT YOU ARE LOOKING FOR" onChange={(e) => setValue(e.target.value)} class="text-[#74c3d5] font-bold text-[26px] w-[80%] leading-[45px] border-none outline-none W-[90%] bg-transparent border-b border-dashed border-gray-400 focus:border-black"/>
          <IoSearch fill="#74c3d5" size={36} />
        </div>
        <div className="mt-[100px] h-[84px] scrollDiv flex justify-start gap-[70px] items-center max-w-[100%] overflow-scroll">
          <div
            className={`flex relative cursor-pointer text-[36px] font-bold leading-[36px] justify-between w-[50px] ${headerValue === "all"
              ? "border-b-[5px] border-dashed border-[#74c3d5] text-[#74c3d5]"
              : "text-[#075c40]"
              }     "`}
            onClick={() => setHeader("all")}
          >
            All{" "}

            {
              headerValue === "all" ? <span className="w-11 h-11 bg-yellow-400 rounded-full text-2xl inline-flex items-center justify-center absolute ml-[50px] mt-[-20px]  text-green-900">
                51
              </span> : ""
            }

          </div>
          <div className="flex gap-[15px] justify-center items-center  ">
            {header.map((item, index) => {
              const filteredLength = filteredData.filter(data => data.type === item.name).length;
              return (
                <>
                  <div
                    key={index}
                    className={`food-text cursor-pointer pb-[10px] ${headerValue === item.name
                      ? "border-b-[5px] border-dashed border-[#74c3d5] text-[#74c3d5] "
                      : "text-[#075c40]"
                      }`}
                    onClick={() => setHeader(item.name)}
                  >
                    {item.name}
                  </div>

                  <div className="bg-[#fd0]   rounded-[50%]   ">
                    {headerValue === item.name && <div className="py-[5px] px-[10px]  text-[20px] font-bold text-[#075c40] ">{filteredLength}</div>}
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="mt-[100px]">
          <div className="px-[20px] grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 gap-10">
            {filteredData.map((data, index) => (
              <FoodCard key={index} data={data} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Menu;
