import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./home.css";
import MultipleItems from "../SampleSlider/SampleSlider";

const Home = () => {
  const [scrollAmount, setScrollAmount] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollAmount(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const moveAmount = scrollAmount / 20;

  return (
    <div>
      <div className="bg-class-home h-[120vh] sm:h-[160vh] xl:h-[210vh] relative ">
        <div className="">
          <Header />
        </div>
        <div className="child-1">
          <MultipleItems />
        </div>
      </div>
      <div className="max-w-[100%] overflow-hidden absolute bottom-[-139%] sm:bottom-[-110%] md:bottom-[-89%] xl:bottom-[-160%] 2xl:bottom-[-120%] xxl:bottom-[-100%] py-[150px]">
      </div>
      <div className="relative mb-[200px] md:mb-[400px] flex justify-center items-center">
        <div className="relative z-10 text-[40px] sm:text-[70px] md:text-[80px] lg:text-[120px] xxl:text-[120px] pt-[70px] md:pt-[120px] justify-center items-center leading-[60px] md:leading-[100px] lg:leading-[140px] xl:leading-[180px] text-center font-bold text-[#075c40]">
          AT PINZARIA
          <div className="text-white" style={{textShadow:"2px 2px 2px green, -2px -2px 2px green, 2px -2px 2px #075c40, -2px 2px 2px green",}}>
            WE BELIVEVE
          </div>
          FRESH IS BEST
        </div>
        <img src={require("./home3.jpg")} alt="" className="absolute left-[20%] top-[50%]  hidden lg:block" />
        <div className="absolute right-[0px] hidden lg:block">
          <img src={require("./home3.jpg")} alt="" />
        </div>
        <div className="absolute top-0 md:transform-rotate-15 trans-class">
          <img src={require("./home5.png")} alt="" className="at-img" />
        </div>
        <div className="bg-[#fd0] cursor-pointer z-100 z-class px-[30px] py-[40px] rounded-[50%] uppercase text-[green] hidden lg:block text-[20px] font-bold leading-[20px] text-center absolute bottom-[-180px] left-[65%]">
          <a href="/about">see what <br /> makes <br /> pinzaria! <br />so great</a>
        </div>
      </div>
      <div className="max-w-[100%] overflow-hidden last-bg pt-[195px] pb-[150px]">
        <div className="move-text-1 py-[100px]">
          <div className="" style={{ transform: `translateX(-${moveAmount}px)` }}>
            NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM
            PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
          </div>
          <div className="" style={{ transform: `translateX(${moveAmount}px)` }}>
            NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM
            PINZARIA! NOM NOM NOM PINZARIA! NOM NOM NOM PINZARIA!
          </div>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default Home;