import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LocationCard from "./LocationCard";

const Location = () => {
  return (
    <div>
      <Header/>
      <div className="py-[200px] px-[20px] md:px-[50px] lg:px-[100px] ">
        <div className="text-[60px] md:text-8xl uppercase mb-8 leading-10 text-[green] font-semibold text-start">
          Virginia{" "}
          <span className="w-11 h-11 bg-yellow-400 rounded-full text-2xl inline-flex items-center justify-center absolute ml-1 mt-[-30px] text-green-900">
            9
          </span>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] md:gap-8">
          <LocationCard code="ashburn" name="Ashburn" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="fairfax" name="Fairfax GMU" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="gainesville" name="Gainesville" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="leesburg" name="Leesburg" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="purcellville" name="Purcellville" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="south-riding" name="South Riding" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="stafford" name="Stafford" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="sterling-herndon" name="Sterling-Herndon" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="warrenton" name="Warrenton" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
        </div>
        <div className="text-[40px] md:text-8xl uppercase mb-8 leading-10 text-[green] fontClass font-bold md:font-semibold mt-[200px] text-start">
          Maryland{" "}
          <span className="w-11 h-11 bg-yellow-400 rounded-full text-2xl inline-flex items-center justify-center absolute ml-1 mt-[-30px] text-green-900">
            2
          </span>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] md:gap-8">
          <LocationCard code="clarksburg" name="Clarksburg" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="urbana" name="Urbana" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
        </div>
        <div className="text-[40px] md:text-8xl uppercase mb-8 leading-10 text-[green] fontClass font-bold md:font-semibold mt-[200px] text-start">
          North Carolina{" "}
          <span className="w-11 h-11 bg-yellow-400 rounded-full text-2xl inline-flex items-center justify-center absolute ml-1 mt-[-30px] text-green-900">
            2
          </span>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] md:gap-8">
          <LocationCard code="holly-springs" name="Holly Springs" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
          <LocationCard code="raleigh" name="Raleigh" sub="ORDER NOW" time="All Week: 11 AM – 1 AM"/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Location;