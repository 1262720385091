import React from "react";
import "./location.css"
const LocationCard = (props) => {
  return (
    <div>
      <div className="border-b-4 border-dashed font-bold mt-[20px] colorClass cursor-pointer md:mt-[50px] text-start w-full border-transparent transition-all duration-500 inline-block text-[33px] md:text-[52px]">
        <a href={`https://order.pinzaria.com/${props.code}`}>{props.name}</a>
      </div>
      <div className="mt-[20px] text-[24px] md:text-xl leading-8 mt-[-3px] mb-6 text-[#075c40] font-extrabold text-start">
        <a href={`https://order.pinzaria.com/${props.code}`}>{props.sub}</a>
      </div>
      <div className="text-base font-GothamMedium_1 text-gray-500 text-start">
        {props.time}
      </div>
    </div>
  );
};

export default LocationCard;