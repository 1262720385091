import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ResponsiveExample from "./OffCanvas";
import "./header.css";
const Header = () => {
  const [color, setColor] = useState("black");
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setColor((prevColor) => (prevColor === "black" ? "green" : "black"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* web View */}
      <div className="  hidden  py-[20px] lg:flex justify-around items-center">
        <div className="flex xxl:gap-[20px]">
          <Link to="/about">
            <div className={`fontClass text-[#075c40] font-bold leading-[26px] xl:font-black uppercase text-[18px] mx-4 xxl:text-[28px] py-2 transition duration-600 ease-in-out ${
                location.pathname === "/about"
                  ? "border-b-[5px] border-dashed border-[#075c40]"
                  : ""
              }`}
            >
              ABOUT
            </div>
          </Link>
          <Link to="/menu">
            <div className={`fontClass text-[#075c40]  font-bold leading-[26px] xl:font-black text-[18px]    xxl:text-[28px] py-2 mx-4 transition duration-600 ease-in-out  ${
                location.pathname === "/menu"
                  ? "border-b-[5px] border-dashed border-[#075c40]"
                  : ""
              }`}
            >
              MENU
            </div>
          </Link>
          <Link to="/locations">
            <div className={`fontClass text-[#075c40]  font-bold leading-[26px] xl:font-black text-[18px]    xxl:text-[28px] py-2 mx-4 transition duration-600 ease-in-out  ${
                location.pathname === "/locations"
                  ? "border-b-[5px] border-dashed border-[#075c40]"
                  : ""
              }`}
            >
              LOCATIONS
            </div>
          </Link>
        </div>
        <div>
          <Link to="/"><img src={require("./logo.png")} alt="" className="w-[175px] xl:w-[200px]" /></Link>
        </div>
        <div className="flex xxl:gap-[20px]">
          <div className="fontClass text-[green]  font-semibold text-[14px]  uppercase py-2  xl:px-4 transition duration-600 ease-in-out py-2 px-3 bg-yellow-400">
            <a href="/locations">ORDER NOW</a>
          </div>
          <div className="fontClass text-[green]  font-semibold  text-[14px]  uppercase py-2  xl:px-4 transition duration-600 ease-in-out py-2 px-3 ">
            <a href="tel:+18885958010">CALL (888) 595-8010</a>
          </div>
          <div className="pointer-events-none flex justify-center items-center gap-[5px]  bg-green-300 py-2  px-3 fontClass text-[green]  font-semibold text-[14px]  uppercase transition duration-600 ease-in-out">
            <div className="w-[10px] h-[10px] bg-[${color}] rounded-[50%]" style={{ backgroundColor: color }}></div>{" "}
            OPEN UNTIL 1:00 AM
          </div>
        </div>
      </div>
      <div className=" block lg:hidden">
        <div className="   bg-green-300 py-[10px] px-[10px]  w-full flex gap-[20px]">
        <div className="pointer-events-none flex justify-center items-center gap-[5px]  bg-green-300 py-2  px-3 fontClass text-[green]  font-semibold text-[14px]  uppercase transition duration-600 ease-in-out">
            <div className="w-[10px] h-[10px] bg-[${color}] rounded-[50%]" style={{ backgroundColor: color }}></div>{" "}
            OPEN UNTIL 1:00 AM
          </div>
          <div className="pointer-events-none flex justify-center items-center gap-[5px]  bg-green-300 py-2  px-3 fontClass text-[green]  font-semibold text-[14px]  uppercase transition duration-600 ease-in-out">
            <a href="tel:+18885958010">CALL (888) 595-8010</a>
          </div>
        </div>
        <div className="lg:hidden flex justify-between mt-[20px] px-[20px]">
          <div className="gap-[10px] justify-center items-center flex md:gap-[20px]">
            <div className="mobileView">
              <ResponsiveExample />
            </div>
            <div>
              <img src={require("./logo.png")} alt="" className="w-[100px] md:w-[175px] xl:w-[200px]"/>
            </div>
          </div>
          <div className="fontClass flex justify-center items-center  h-[40px] md:h-[50px] text-[green] px-[10px] md:px-[20px] font-semibold text-[14px]  uppercase   transition duration-600 ease-in-out  bg-yellow-400">
            <a href="/locations">ORDER NOW</a>
          </div>
        </div>
      </div>
      {/* mobile View */}
    </>
  );
};

export default Header;